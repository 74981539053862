import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MassiveService {

  private url_la: any;
  
  private env: any = environment;

  constructor(
    public httpClient: HttpClient,
  ) {
    this.url_la = this.env.BASE_LA;
  }

  get baseUrl() {
    return this.url_la;
  }

  getConfig = async (bank: string, type: string, name: string): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {
      bank,
      type,
      name
    };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/massive/configs', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  readCustomFile = async (config: any, file: any, method: any): Promise<any> => {
    const headers = { 'content-type': 'multipart/form-data' };
    const form = new FormData();
    form.append('method', method);
    form.append('config', config);
    form.append('archivo', file);
    try {
     return lastValueFrom(
       this.httpClient.post<any>(
         `${this.baseUrl}/api/massive/read_response_bank_custom`,
         form,
         { headers: {} , params: {} }
       )
     );
    } catch (error) {
      
      console.error(error);
      return false;
    }
  };


  getMassiveProcess = async (bank: string, type: string, date: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {
      bank,
      type,
      date
    };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/massive', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getMassiveProcessStatuses = async (massive_process_id: string): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {
      massive_process_id
    };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/massive/massive_status',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  pendingLote = async (): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = { };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/massive/pending_lote',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  conciliar = async (body: any, file: any): Promise<any> => {
    const headers = { 'content-type': 'multipart/form-data' };
    const form = new FormData();
    form.append('obj', body);
    form.append('archivo', file);

    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/massive/conciliation`,
          form,
          { headers: {} , params: {} }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  finish = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/massive/finish`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  historic = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/massive/history`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

}
