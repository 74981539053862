<div class="row">
    <div class="col-12 mt-2">
      <div class="mt-2 mb-5">
     
        <form autocomplete="nope" novalidate [formGroup]="form" class="text-start">
            <div class="row mt-3">
    
                <div class="form-group col-12 mt-3">
    
                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form.controls.name.invalid && form.controls.name.dirty}" class="form-control text-capitalize" id="name" formControlName="name" placeholder="name@example.com">
                        <label for="name" class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span >Primer nombre</span>
                    <span class="text-danger ps-1">*</span>
                    </label>
                    </div>
    
                    <div class="form-control-feedback help-block" *ngIf="form.get('name').errors">
                        <div *ngIf="form.get('name').errors['required'] && !form.get('name').pristine">
                            Campo requerido
                        </div>
                    </div>
    
                </div>
    
                <div class="form-group col-12 mt-3">
    
                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form.controls.second_name.invalid && form.controls.second_name.dirty}" class="form-control text-capitalize" id="second_name" formControlName="second_name" placeholder="second_name@example.com">
                        <label for="second_name" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Segundo nombre</span>
                </label>
                    </div>
    
    
                </div>
    
                <div  class="form-group col-12 mt-3">
    
                    <div class="form-floating">
                        <input  type="text" [ngClass]="{'error': form.controls.lastName.invalid && form.controls.lastName.dirty}" class="form-control text-capitalize" id="lastName" formControlName="lastName" placeholder="name@example.com">
                        <label for="lastName" class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span >Primer apellido</span>
                        <span class="text-danger ps-1">*</span>
    
                    </label>
                    </div>
    
                    <div class="form-control-feedback help-block" *ngIf="form.get('lastName').errors">
                        <div *ngIf="form.get('lastName').errors['required'] && !form.get('lastName').pristine">
                            Campo requerido
                        </div>
                    </div>
    
                </div>
    
    
                <div class="form-group col-12 mt-3">
    
                    <div class="form-floating">
                        <input type="text" [ngClass]="{'error': form.controls.second_lastName.invalid && form.controls.second_lastName.dirty}" class="form-control text-capitalize" id="second_lastName" formControlName="second_lastName" placeholder="second_name@example.com">
                        <label for="second_lastName" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Segundo apellido</span>
                </label>
                    </div>
    
    
                </div>

                <div class="form-group col-12  mt-3">

                    <div class="text-secondary text-end fz-12">
                        Eje: ejemplo@email.com
                    </div>
    
                    <div class="input-group mb-3">
                        <div class="form-floating ">
                            <input type="text" [ngClass]="{'error': form.controls.email.invalid && form.controls.email.dirty}" class="form-control" id="email" formControlName="email" placeholder="name@example.com">
                            <label for="email" class=" label font-weight-normal" style="word-wrap: break-word;">
                                <span >Email</span>
                            </label>
                        </div>
                    </div>
    
                    <div class="form-control-feedback help-block" *ngIf="form.get('email').errors">
                        <div *ngIf="form.get('email').errors['required'] && !form.pristine">
                            Campo requerido
                        </div>
    
                    </div>
                    <div class="form-control-feedback help-block" *ngIf="form.get('email').hasError('pattern')  && !form.pristine">
                        <span>Email invalido</span>
                    </div>
                    <div class="form-control-feedback help-block" *ngIf="contact_email_val_err">
                        <div >
    
                            Verifique el email, el mismo ya existe
                        </div>
    
                    </div>
                </div>
    
                <div class="form-group col-12  mt-3">
    
                    <div class="text-secondary text-end fz-12">
                        Eje: 4240000000
                    </div>
    
                    <div class="input-group mb-3">
                        <div class="t58" *ngIf="form.getRawValue()?.contact != ''">
                            +58
    
                        </div>
    
                        <div class="form-floating ">
                            <input type="text" [ngClass]="{'error': form.controls.contact.invalid && form.controls.contact.dirty,'t582':form.getRawValue()?.contact != ''}" class="form-control " id="contact" formControlName="contact" placeholder="name@example.com">
                            <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                                <span >Número celular</span>
                            </label>
                        </div>
                        
                        <!-- <button (click)="fz()" [disabled]="!form.valid || submit_disabled" class="btn btn-primary">
                            Validar
                            <span *ngIf="submit_disabled_contact">
                            <i class="fa  fa-spinner fa-spin"></i>
                            </span>
                        </button>
                        <button *ngIf="!env?.production" (click)="showValidateTel2()" [disabled]="!form.valid || submit_disabled" class="btn btn-secondary ml-2">
                            No validar
                        </button> -->
                    </div>
    
    
                    <div class="form-control-feedback help-block" *ngIf="form.get('contact').errors">
                        <div *ngIf="form.get('contact').errors['required'] && !form.pristine">
                            Campo requerido
                        </div>
                    </div>
    
    
                    <div class="form-control-feedback help-block" *ngIf="contact_phone_val_err">
                        <div >
                            Verifique el número celular, el mismo ya existe
                        </div>
                    </div>
    
    
                </div>
    
                <br>
                <div class="form-group  col-12 mt-4 mb-2">
                    <button type="submit"  (click)="showValidateTel()"  [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary float-end ">
                                    <span >Continuar</span>
                                    <span *ngIf="loading" >
                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                    </span>
                                  </button>
                </div>
    
    
    
    
            </div>
        </form>
        <ng-container *ngIf="loadForm || loadPerson_Address">
          <div class="w-100 text-center mt-2">
            <img src="./assets/public/images/loading.gif " alt=" " />
          </div>
        </ng-container>
      </div>
  
    </div>
  </div>
  