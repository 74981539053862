

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions, MbscFormOptions, MbscDatetimeOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
};


@Component({
  selector: 'app-search-creator-client-of-orders',
  templateUrl: './search-creator-client-of-orders.component.html',
  styleUrls: ['./search-creator-client-of-orders.component.css'],
  providers: [DatePipe],

})
export class SearchCreatorClientOfOrdersComponent implements OnInit {

  
  @Output() close = new EventEmitter<any>();
  @Input() type: any;


  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;
  person_data:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;
  result:any;
  buro:any={};
  loading:any;

  submit_disabled_contact:any;
  validate_contact:any;

  activeValidateTlf = false;
  dataValidateTlf:any;
  email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";
  buro_loading:any;

  date_base: any = new Date;
  date_base_string :any;
  

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};

  mobileSettings: MbscDatetimeOptions = {
      display: 'bottom',
      themeVariant: 'dark',
  };
  

  desktopSettings: MbscDatetimeOptions = {
      display: 'bubble',
      touchUi: false
  };

  formSettings: MbscFormOptions = {
      inputStyle: 'box'
  };
  env = environment;
  show_data_buro:any;
  
  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private datePipe: DatePipe,
    private router: Router,
    public _auth: AuthService


  ) { }

  ngOnInit(): void {
    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    this._user.tlfValidates$.subscribe((response) => {

      // if(response[this.validate_contact]){

       if(response[this.dataValidateTlf]){
          this.loading = true;
       } 

    });
 

/*
    this._user.showValidateTel$.subscribe((response) => {
      if (response) {
          this.activeValidateTlf = true;
          this.dataValidateTlf = response;
      }
    });*/


    this.birthday =  new Date(`06/02/1988`);


    localStorage.removeItem('new_person');
    this.searchPerson =null;

    this.form = this.formBuilder.group({

      type_identity: ['RIF',[Validators.required]],
      code_identity: ['J',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(20)],]
      
  });



  this.form3 = this.formBuilder.group({
    name: [''],
    lastName: [''],
    second_name: [''],
    second_lastName: [''],
    marital_status: [''],
    gender: [''],
    contact: ['',[Validators.required]],
    email: ['', [Validators.required,Validators.pattern(this.email)]]   

  });

  
  this.getLists();
  
  }

  getLists = async () =>  {
    const [marital_status, type_identity,code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }

  submit = async () =>  {
    this.result=false;
    this.person = null;
    if ( this.form.valid) {
        this.submit_disabled = true;
        let data = this.form.getRawValue();

        if(this.type){
          data.type =  this.type
        }

    try {

         
      let resp = await this._user.getPersonByIdentifier(data);
      this.searchPerson =data;
      this.result=true;
      let  types  = resp?.resp?.person?.params?.type ;
      const type_person_exist = types?.includes(this.type);
      if(resp?.resp){
        const resp2 = await this._user.getPersonbyCode({ code: resp?.resp?.person.code });
        this.person_data = resp2?.resp;

        
      }

      if(!type_person_exist){

        this.createPerson();
      }else{
        this.person = resp?.resp;

      }


      this.submit_disabled = false;

     } catch (error:any) {

        this.submit_disabled = false;
     }
  }
}


createPerson(){

  localStorage.setItem('new_person',JSON.stringify(this.searchPerson));
  this.step = 3;
  this.person = this.searchPerson;

if(this.person_data){
 
  const person  = this.person_data.person ;

 let phone =  this.getContact( this.person_data?.contacts || [],"PHONE_CELL");

 phone = phone?.replace(/(\+58|58)/g, "");

 let email =  this.getContact( this.person_data?.contacts || [],"EMAIL");

  this.form3 = this.formBuilder.group({ 
    
    contact: [ phone,[Validators.required]],
    email: [email, [Validators.required,Validators.pattern(this.email)]],
  
  });



}


}

  // simple handler without any parameters
  birthdaySet() {
    const date: any = this.datePipe.transform(this.birthday, 'dd/MM/yyyy');
    this.form3.patchValue({
      birth_date: date
   });
  }


  sendPerson = async () => {

          debugger

      if ( this.form.valid) {

        try {


        this.submit_disabled = true;
        const form = this.form3.getRawValue();




        let contact = [
          { type_contact:"PHONE_CELL",
             verificated_contact:false,
             contact: `+58${form?.contact}`
          },
          { type_contact:"EMAIL",
            verificated_contact:false,
            contact:form?.email
          }
        ]


       let form_resp = Object.assign({}, form, this.person);
       form_resp = {
         ...form_resp,
       ejecutive:this._auth.sessionUser.person_code,
       "audience": this.form.getRawValue()?.type_identity == 'RIF'? "LEGAL" :"NATURAL",
      'contacts':contact,
       "type":this.type

      }

    //  
       
      
       
            let resp = await this._user.createPerson(form_resp);

            this.close.emit(true);
debugger
             this.router.navigate([`/console/person/profile/${resp?.resp}`], {});


            alertifyjs.success('Creacion exitosa');
           //  this.router.navigate([environment.loginRoute], {});
             
           } catch (error:any) {
             
              alertifyjs.error('Error al crear el usuario');
              console.error(error);
              this.submit_disabled = false;
           }
      }

      
  }



getCodeBuroIdentity(code:any){
  let resp;
switch (code) {
  case "V":
    resp = 1;
    break;
    case "CP":
      resp = 2;
      break;
      case "E":
        resp = 3;
        break;
        case "P":
          resp = 4;
          break;

}

return resp;
}


 jsDateToEpoch = (d:any) => {

  // d = javascript date obj
  // returns epoch timestamp
  return (d.getTime())
}


  showValidateTel = () =>{

    let contact =  this.form2.getRawValue()?.contact;

   // contact = "+584241724412";
   this.step = 4;

   this.activeValidateTlf = true;
   this.dataValidateTlf = `+58${contact}`;

    this._user.setShowValidateTel(`+58${contact}`);
      
  }



  closeValidateTlf = (e:any) => {

    this.activeValidateTlf = false;
    this.step = 2;


}

setValidateTlf = (e:any) => {
    let datas = this._user.tlfValidates$.getValue();

    datas[e] = true;

    this._user.setTlfValidates(datas);

}
  



  sendNoteClient = async (msg:any,phone:any) => {
    
    try {
      const data =  {
          phone,
          msg,
          "client": "CrediCanguro"
        }
    
            let result = await this._user.sendsmsGeneral(data);
    
            if (!(result?.error)) {
    
    
            }
    
    } catch (error) {
      console.error(error);
      
      
    }
  
    



 }

  
dateSet(e: any) {

  let date_base =  e.valueText;
  
  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;
  




}


getContact(item: any,type:any) {

  
  let contact = item.find((contact:any)=> contact?.type_contact?.code == type );  

  return contact?.contact || '';

}





}
