


<div class="mt-md-3 container">
    <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Ordenes de pago
    </h5>


<ng-container >
    <div class="row rmt-1" >
        <div  class="col-12 col-md-8 offset-md-2">


        <ng-container *ngIf="loading ">
            <div class="w-100 text-center text-md-start ">
                <img src="./assets/public/images/loading.gif " alt=" ">
            </div>
        </ng-container>

        <div  *ngIf="rows?.length < 1 && !loading " class="h5 text-center mt-4">

            No se encontraron ordenes de pago

        </div>


            <ng-container *ngFor="let item of rows">
                <div class="mt-5 p-2 bg-body rounded shadow-sm">
                    <div class="row mt-2 "       
                                  (click)="pocisionBottom3(invoicetp,{ code_invoice:item?.orden.code_invoice, code_installment: item?.orden.code_installment,code_person:item?.orden.code_person})"
                    >

                        <div class="col-6 mb-1">
                            <div class=" text-secondary">Orden</div>
                            
                            <div class="fw-bolder text-primary"> {{item?.orden?.order}}  </div>

                        </div>

                        <div class="col-6 mb-1 text-end" >
                            <div class=" text-secondary">Fecha</div>
                            
                            <div class=" text-dark small"> {{item?.fecha | date: 'dd/MM/yyyy'}}</div>
                
                         </div>

                        <div class=" col-6 mb-1">
                            <div class=" text-secondary">Desde</div>
                            
                            <div class="fw-bolder text-dark small"> {{item?.suscriptior }}  </div>
                        </div>

                        <div class=" col-6 mb-1 text-end">
                            <div class=" text-secondary">Hacia</div>
                            
                            <div class="fw-bolder text-dark small"> {{item?.proveedor }}  </div>
                        </div>

                        
                        <div class="col-12 col-6 mb-1">
                            <div class=" text-secondary">Monto</div>
                            
                            <div class=" text-primary "> {{item?.moneda}}  {{item?.monto | currency:'USD':'': '1.2-2'}}  </div>

                        <div class="col-12 mt-3 ">
                            <div class=" text-secondary">Descripcion</div>
                            
                            <div class="text-dark"> {{item?.description }}  </div>
                        </div>

                        </div>




                        
                    </div>
                </div>
            </ng-container>
         </div>
        </div>
</ng-container>

</div>



<ng-template #invoicetp let-offcanvas>
  <div class="offcanvas-header">
      <div class="container ">
          <div class="row">
              <div class="col-12 col-md-10 offset-md-1 ">
                  <div class="row">
                      <div class="col-10">
                          <div class="text-capitalize h5 text-secondary mt-1 ">
                             Invoice
                   
                          </div>
                      </div>
                      <div class="col-2 text-end pt-2">
                          <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="offcanvas-body">
      <div class=" container">

          <div class="row">
              <div class=" col-12 col-md-10 offset-md-1">

                  <div class="text-start">
                    
                      <app-contact-order-payment  [code_invoice]="params_invoice.code_invoice" [code_installment]="params_invoice?.code_installment" [code_person]="params_invoice?.code_person"     (resetStatus)="setResetStatus($event)" ></app-contact-order-payment>

                  </div>
              </div>
          </div>

      </div>
  </div>
</ng-template>



