


<div class="mt-md-3 p-3">
    <div class="row ">
        <div class=" col-12 ">
        <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Ordenes de pago</h5>
        </div>
    </div>

   
    <div class="row pb-4">
        <div class=" form-group  col-12 col-md-3 input-container">
            <label>Numero de Orden:</label>
            <input
                style="background: white"
                id="orden"
                (change)="filter()"
                (keyup)="filter()"
                [(ngModel)]="selectedOrden"
                class="form-control form-control-sm"
                title="Clear"
              />
              <i class="fas fa-times close-icon" aria-hidden="true" (click)="clearOrden()"></i>
             
        </div>
        <div class=" col-6 col-md-3 ">
            <label>Etapa:</label>
            <select class="form-select form-select-sm" [(ngModel)]="selectedResponsable"  aria-label="Default select example" (change)="filter()">
                <option value="" selected>Todos</option>
                <option *ngFor="let step of steps" [value]="step.code">{{ step.name }}</option>
                <option value="Finalizado" selected>Finalizado</option>

            </select>
        </div>
        <div class=" col-6 col-md-3">
            <label>Estatus:</label>
            <select class="form-select form-select-sm" [(ngModel)]="selectedStatus"  aria-label="Default select example" (change)="filter()">
                <option value="" selected>Todos</option>
                <option value="Completado">Completado</option>
                <option value="Pendiente">Pendiente</option>
                <option value="Borrador">Anulado</option>
              </select>
        </div>

        <div class=" col-6 col-md-3">
            <label>Banco de Origen:</label>
            <select class="form-select form-select-sm" [(ngModel)]="bank_filter"  aria-label="Default select example" (change)="filter()">
                <option value="" selected>Todos</option>
                <option  *ngFor="let item of banks; let i=index" [value]="item?.code" >{{item?.name}}</option>
              </select>
        </div>

        

        
        <div class=" col-6 col-md-3 col-md-3 mt-2 input-container">
            <label>Fecha creación:</label>
            <input
                style="background: white"
                id="demo-external"
                (onSet)="filter()"
                [(ngModel)]="selectedRange"
                mbsc-range
                class="form-control form-control-sm"
                [mbsc-options]="dateSetting"
                #externalRange="mobiscroll"
                title="Clear"
              />
              <i class="fas fa-times close-icon" aria-hidden="true" (click)="clearRange()"></i>
             
        </div>

        <div class="form-group  col-3 dash" >

            <div>
                
                <label>Emisor</label>
                <mbsc-select
                  id="mySelect"
                  [(ngModel)]="emisor_filter"
                  [filter]="true"
                  [data]="options"
                  display="bubble"
                  placeholder="Seleccione una opción"
                  valueField="value"
                  textField="text"
                  (onSet)="filter()" 
                  locale="es"
                  >

                </mbsc-select>


              </div>
        </div> 


        <div class="form-group  col-3 dash" >

            <div>
                
                <label>Receptor</label>
                <mbsc-select
                  id="mySelect"
                  [(ngModel)]="receptor_filter"
                  [filter]="true"
                  [data]="options2"
                  display="bubble"
                  placeholder="Seleccione una opción"
                  valueField="value"
                  textField="text"
                  (onSet)="filter()" 
                  locale="es"
                  >

                </mbsc-select>


              </div>
        </div> 

        <div class=" col-6 col-md-3  mt-2 " >
            <label>Ordenar por:</label>
            <select class="form-select form-select-sm" [(ngModel)]="sort_filter"  aria-label="Default select example" (change)="filter()">
                <option value="dateEnd">Fecha de registro</option>
                <option value="priority">Prioridad</option>
              </select>
        </div>

        

    </div>
    <div class="row ">
        <div class=" col-12 ">
            <div *ngIf="loading"> 
                <i class="pl-3 fas fa-spinner fa-spin" ></i> &nbsp;Cargando... <br><br>
            </div>
            <ng-container *ngIf="totalRows!==null">
                <div class="container-fluid-2">
                        <div class="row">
                        <div class="col-md-8">
                            
                           
                            <div class="form-group text-right pull-right">
                                <select (change)="onChangeLimit($event)" class="custom-select " >
                                    <option [value]="10" [selected]="10  === limit"> 10 </option>
                                    <option [value]="20" [selected]="20  === limit"> 20 </option>
                                    <option [value]="50" [selected]="50  === limit"> 50 </option>
                                    <option [value]="100" [selected]="100  === limit"> 100 </option>
                                    <option [value]="200" [selected]="200  === limit"> 200 </option>
                                    <option [value]="500" [selected]="500  === limit"> 500 </option>
                                    <option [value]="1000" [selected]="1000  === limit"> 1000 </option>
                                </select><br>
                                <div class="pagination-info">
                                    <div>Página {{ page+1 }} de {{ totalPages }}</div>
                                    <div>Total de registros: {{ totalRows }}</div>
                                </div>
                            </div>

                        </div>
                        
                        <div class="col-md-4">

                            
                            
                            <div class="form-group text-right pull-right">
                               

                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item">
                                        <a class="page-link" href="javascript:void()" (click)="changePage(0)" aria-label="First">
                                            <span aria-hidden="true">&laquo;</span>
                                            <span class="sr-only">First</span>
                                        </a>
                                        </li>
                                        <li class="page-item">
                                        <a class="page-link" href="javascript:void()" (click)="changePage(page-1)" aria-label="Previous">
                                            <span aria-hidden="true">&larr;</span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        </li>
                                        <li class="page-item">
                                        <a class="page-link" href="javascript:void()" (click)="changePage(page+1)" aria-label="Next">
                                            <span aria-hidden="true">&rarr;</span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                        </li>
                                        <li class="page-item">
                                        <a class="page-link" href="javascript:void()" (click)="changePage(totalPages-1)" aria-label="Last">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span class="sr-only">Last</span>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>


                            </div>
                        </div>
                        </div>
                  </div>
                  
            </ng-container>


             <ng-container >
                <ngx-datatable
                class="material"
                [rows]="rows"
                [columns]="columns"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [scrollbarH]="true"
                style="width: 100%;">
                
                <ngx-datatable-column *ngFor="let column of columns" [name]="column.prop" [width]="column.width">
                  <ng-template ngx-datatable-cell-template let-row="row">
                    
                    
                    <span *ngIf="column.prop == '#'" >{{row[column.prop]}}</span>
                    <span *ngIf="column.prop == 'orden' "  class="text-primary cursor-pounter"
                    
                    (click)="pocisionBottom3(invoicetp,{ code_invoice:row[column.prop].code_invoice, code_installment: row[column.prop].code_installment,code_person:row[column.prop].code_person})"
                    >{{row[column.prop].order}}</span>

                    

                  <span *ngIf="column.prop == '-'">                        
                    <i class="fas fa-lock text-danger" *ngIf="row[column.prop]"></i>
                    <i class=" text-secondary" *ngIf="!row[column.prop]">-</i>

                  </span>
  <!-- 
                    <span *ngIf="column.prop == 'archivos' && row[column.prop] > 0">
                        {{row[column.prop]}} 
                        <i class="text-warning far fa-file cursor-pounter"></i>
                        
                    </span>

                    <span *ngIf="column.prop == 'note'"><i class="text-warning far fa-comment-alt cursor-pounter"></i></span>
                    -->
                    <span *ngIf="column.prop == 'organizacion'"  class="text-primary cursor-pounter" [routerLink]="['/console/person/profile/',row[column.prop]?.code]" >{{row[column.prop]?.name}}</span>
                    <span *ngIf="column.prop == 'proveedor' "  class="text-primary cursor-pounter"[routerLink]="['/console/person/profile/',row[column.prop]?.code]">{{row[column.prop]?.name}}</span>
 
                    <!-- <span *ngIf="column.prop == 'hora'">{{row[column.prop]}}</span>-->
                    <span *ngIf="column.prop == 'creacion'">{{row[column.prop]}}</span>
                    <span *ngIf="column.prop == 'fecha_emision'">
                        
                       {{row[column.prop]}} 
                       </span>

                       <span *ngIf="column.prop == 'fecha_vencimiento'">
                        
                        {{row[column.prop]}} 
                        </span>
 




                       <span *ngIf="column.prop == 'vencimiento'" >

                        <ng-container *ngIf="row[column.prop]?.s?.code == 'PaymentPending'; else elseTemplate">



                    
                            <span  *ngIf="calculateDays(row[column.prop]?.v)?.diasFaltantes > 5">  
                                {{calculateDays(row[column.prop]?.v)?.diasFaltantes}} d 
                                
                                </span>
                            <span class="text-warning"  *ngIf=" calculateDays(row[column.prop]?.v)?.diasFaltantes < 6 && calculateDays(row[column.prop]?.v)?.diasFaltantes > 1 ">      {{calculateDays(row[column.prop]?.v)?.diasFaltantes}} d</span>
                            <span class="text-danger"  *ngIf=" calculateDays(row[column.prop]?.v)?.diasFaltantes < 2"> 
                                
                                <span *ngIf="!calculateDays(row[column.prop]?.v)?.fechaEstado">  {{calculateDays(row[column.prop]?.v)?.diasFaltantes}} d</span>
                                <span *ngIf="calculateDays(row[column.prop]?.v)?.fechaEstado">{{calculateDays(row[column.prop]?.v)?.fechaEstado}} </span>                        
                            
                            </span>
                        </ng-container>


                        
                  
                        <ng-template #elseTemplate>
                            <span>-</span>
                            
                        </ng-template>
                        
                        </span>


                        <span *ngIf="column.prop == 'prioridad'">
                        
                            <span [ngClass]="{'text-secondary': row[column.prop].code == 1 , 'text-warning': row[column.prop].code == 2,'text-danger': row[column.prop].code == 3}" > {{row[column.prop].name}} </span>   
                       
                        </span>
     
     

                        



                    <span *ngIf="column.prop == 'moneda'">{{row[column.prop]}}</span>


                    <span *ngIf="column.prop == 'banco_emisor' "  class="cursor-pounter"
                    
                    >{{row[column.prop]?.name?row[column.prop]?.name:'-'}}</span>
                    

                    <div *ngIf="column.prop == 'estatus'">

                        

                        <span class="text-danger" *ngIf="row[column.prop]?.code == 'PaymentTrash'">
                            Anulado
                        </span>

                        <span *ngIf="row[column.prop]?.code != 'PaymentTrash'">
                            {{row[column.prop]?.name}}
                        </span>
                    
                    
                    </div>


                      
                    <span *ngIf="column.prop == 'etapa'">
                        <span  *ngIf="!row[column.prop].name" [ngClass]="{'text-success': !row[column.prop].name }">Finalizado</span>
                        <span  *ngIf="row[column.prop].name">   {{row[column.prop].name}}</span>
                    
                    </span>
                    
                    <span *ngIf="column.prop == 'monto'">{{row[column.prop]}}</span>
                    <!-- 
                    <button *ngIf="column.prop === 'accion'" class="btn btn-primary btn-sm" (click)="responder(row.orden)">Responder</button>
                    -->
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>

            <!--  <pre class="cursor-pounter">{{ invoices | json }}</pre> --> 
              

</ng-container>

<ng-container *ngIf="totalRows!==null">
    <div class="container-fluid-2 pt-4">
            <div class="row">
            <div class="col-md-8  ">
                
               
                

            </div>
            
            <div class="col-md-4">

                
                
                <div class="form-group text-right pull-right">
                   

                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                            <li class="page-item">
                            <a class="page-link" href="javascript:void()" (click)="changePage(0)" aria-label="First">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">First</span>
                            </a>
                            </li>
                            <li class="page-item">
                            <a class="page-link" href="javascript:void()" (click)="changePage(page-1)" aria-label="Previous">
                                <span aria-hidden="true">&larr;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                            </li>
                            <li class="page-item">
                            <a class="page-link" href="javascript:void()" (click)="changePage(page+1)" aria-label="Next">
                                <span aria-hidden="true">&rarr;</span>
                                <span class="sr-only">Next</span>
                            </a>
                            </li>
                            <li class="page-item">
                            <a class="page-link" href="javascript:void()" (click)="changePage(totalPages-1)" aria-label="Last">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Last</span>
                            </a>
                            </li>
                        </ul>
                        </nav>


                </div>
            </div>
            </div>
      </div>
      
</ng-container>
</div>
</div>
</div>



<ng-template #invoicetp let-offcanvas>
  <div class="offcanvas-header">
      <div class="container ">
          <div class="row">
              <div class="col-12 col-md-10 offset-md-1 ">
                  <div class="row">
                      <div class="col-10">
                          <div class="text-capitalize h5 text-secondary mt-2 ">
                             Invoice
                   
                          </div>
                      </div>
                      <div class="col-2 text-end pt-2">
                          <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="offcanvas-body">
      <div class=" container">

          <div class="row">
              <div class=" col-12 col-md-10 offset-md-1">



                  <div class="text-start">




                    
                      <app-contact-order-payment  [code_invoice]="params_invoice.code_invoice" [code_installment]="params_invoice?.code_installment" [code_person]="params_invoice?.code_person"     (resetStatus)="setResetStatus($event)" ></app-contact-order-payment>

                  </div>
              </div>
          </div>

      </div>
  </div>
</ng-template>