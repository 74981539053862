// invoice-types.ts
export interface InvoiceType {
    name: string;
    code: string;
    plural: string;
}

export const person_types_all: InvoiceType[] = [
    {
        name: "Organización",
        code: "SUSCRIPTOR",
        plural: "suscriptors"
    },
    {
        name: "Proveedor",
        code: "PROVIDER",
        plural: "providers"
    },
    {
        name: "Nomina",
        code: "PAYROLL",
        plural: "payrolls"
    },
    {
        name: "Accionista",
        code: "SHAREHOLDER",
        plural: "shareholders"
    }
];


export const person_types: InvoiceType[] = [
    {
        name: "Organización",
        code: "SUSCRIPTOR",
        plural: "suscriptors"
    },
    {
        name: "Proveedor",
        code: "PROVIDER",
        plural: "providers"
    },
    {
        name: "Empleado",
        code: "PAYROLL",
        plural: "payrolls"
    }
];

export const person_types_code_obj= {
    "CLIENT": {
        name: "Cliente",
        code: "CLIENT",
        plural: "Clientes"
    },
   "SUSCRIPTOR": {
        name: "Organización",
        code: "SUSCRIPTOR",
        plural: "suscriptors"
    },
    "PROVIDER":{
        name: "Proveedor",
        code: "PROVIDER",
        plural: "providers"
    },
    "PAYROLL":{
        name: "Empleado",
        code: "PAYROLL",
        plural: "payrolls"
    },
    "SHAREHOLDER": {
        name: "Accionista",
        code: "SHAREHOLDER",
        plural: "shareholders"
    }
};



export const person_types_plural_obj= {
    "suscriptors": {
         name: "Organización",
         code: "SUSCRIPTOR",
         plural: "suscriptors"
     },
     "providers":{
         name: "Proveedor",
         code: "PROVIDER",
         plural: "providers"
     },
     "payrolls":{
         name: "Empleado",
         code: "PAYROLL",
         plural: "payrolls"
     },
     "shareholders": {
         name: "Accionista",
         code: "SHAREHOLDER",
         plural: "shareholders"
     }
 };
 
 
export const person_types_code_description_obj= {
    "CLIENT": {
        name: "Cliente",
        code: "CLIENT",
        plural: "Clientes"
    },
    "SUSCRIPTOR": {
        name: "Organización",
        code: "SUSCRIPTOR",
        plural: "Organizaciones"
    },
    "PROVIDER":{
        name: "Proveedor",
        code: "PROVIDER",
        plural: "Proveedores"
    },
    "PAYROLL":{
        name: "Empleado",
        code: "PAYROLL",
        plural: "Empleados"
    },
    "SHAREHOLDER": {
        name: "Accionista",
        code: "SHAREHOLDER",
        plural: "Accionistas"
    }
};